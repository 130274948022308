<template>
    <div class="Copy_index clearfix">
        <div class="Copy_index_l fl">
            <div class="l_nav">
                <div class="l_title"  @click="backss()">
                    <i class="iconfonts icons-shouyefill"></i>
                    <span>返回上一页</span>
                </div>
                        <div class="l_item">
                            <div class="item_kc">
                                <span>{{form.team}}</span>
                            </div>
                                <ul class="item_ul">
                                    <li v-for="(item,index) in form.users" :key="index">
                                        <span class="z_span" v-show="item.type==1">组长</span>
                                        <span class="z_spans" v-show="item.type==0">组员</span>
                                        <img :src="item.avatar" alt="">
                                        <span :title="item.name">{{item.name}}</span>
                                    </li>
                                </ul>
                        </div>
                    </div>
        </div>
        <div class="Copy_index_r fl">
            <div class="main_div">
                <div class="step_div">
                    <ul class="step_div_steps">
                        <li v-for="(item,index) in stepsList" :key="index">
                            <div class="bor_divsss" :class="item.state!=0?'actice_div':''">
                                <div class="bor_div_top">
                                    <el-progress type="circle" 
                                        :percentage="form.data.step==item.id?100:0" 
                                        :stroke-width="8" 
                                        :show-text='false' 
                                        :color="'#54A9D8'"
                                    ></el-progress>
                                    <i class="iconfonts" :class="item.icon"></i>
                                </div>
                                <div class="bor_div_title" :class="form.data.step==item.id?'acti_title':''">
                                    {{item.name}}
                                </div>
                            </div>
                            <div class="bor_div_r" :class="item.state!=0?'acti_div_r':''" v-show="index!= stepsList.length - 1">
                                <div class="one_r">

                                </div>
                                <div class="two_r">

                                </div>
                                <div class="two_rs">

                                </div>
                                <div class="three_r">
                                   <i class="el-icon-arrow-right"></i>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="content_div">
                    <router-view @info="valuess"></router-view>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form:{
                data:{},
                course:{},
                team:'',
                users:[]
            },
            stepsList:[]
        };
    },
    methods: {
        getapp(){
            this.calId=this.$route.query.calId
            this.stepsApp()
        },
        stepsApp(){
            this.axios.Teacreatedetail({
                token:this.$store.state.token,
                id:this.calId
            }).then(res=>{
                if(res.data.code==0){
                    this.stepsList = res.data.data.step.steps
                }else{
                    this.$message.error(res.data.message)
                    this.stepsList = []
                }
            }).catch(err=>{

            })
        },
        backss(){
            this.$router.push({name:'Coursedetails',query:{calId:this.calId,num:2}})
        },
        valuess(data){
            this.form = data
        }
    },
    mounted() {
        this.getapp()
    }
};
</script>
<style scoped>
@import './../../../assets/css/teacher/Copypaper/CopyIndex.css';
</style>
<style>
.bor_div_top .el-progress{
    position: absolute;
    transform: rotate(180deg);
}
.bor_div_top .el-progress-circle{
    width: 50px!important;
    height: 50px!important;
}
</style>